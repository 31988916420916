.panels{
	padding:155px 0 135px;
	border-top:1px solid rgba(151,151,151,.47);
	overflow:hidden;
	@media screen and (max-width:$break){
		padding:40px 0;
	}
	.block{
		clear: both;
		width: 100%;
		padding-top:200px;
		@media screen and (max-width:$break){
			padding-top:20px;
		}
		&:first-of-type{
			padding-top:0;
		}
		&:nth-of-type(odd) .text-block{
			padding-left:60px;
			@media screen and (max-width:$tablet){
				padding:0;
			}
		}
		&:nth-of-type(even) .text-block{
			padding-right:60px;
			@media screen and (max-width:$tablet){
				padding:0;
			}
		}
		.col-50-gutter {
			position:relative;
			height: 380px;
			@media screen and (max-width:$break){
				height: auto;
			}
			&.text-block {
				padding-top:24px;
				@media screen and (max-width:$break){
					padding:24px 40px;
				}
			}
			h3,p{
				color:$primary-color;
			}
			h3{
				font-family: $primary-font-bold;
				padding-bottom:30px;
				font-size: 36px;
			}
			p{
				font-family: $primary-font-light;
				line-height: 24px;
			}
			.button{
				border:2px solid $primary-color;
				color:$primary-color;
				text-decoration: none;
				font-family: $primary-font-regular;
				padding:10px 26px 8px;
				@include box-sizing(border-box);
				position: absolute;
				top:260px;
				@include border-radius(30px);
				&:hover{
					background-color: $primary-color;
					color:#ffffff;
				}
				@media screen and (max-width:$break){
					position:relative;
					top:auto;
					margin-top: 20px;
					display: inline-block;
				}
			}
			img {
				max-width: 506px;
				@media screen and (max-width:$break){
					max-width: 100%;
					text-align:center;
					display: block;
					margin:0 auto;
					padding:50px 0;
				}
			}
		}
	}
}