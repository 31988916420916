.banner {
	padding:300px 0 200px;
	text-align:center;
	position:relative;
	background-image:url('../images/map.jpg');
	background-position:center;
	background-size:cover;
	@media screen and (max-width:$tablet){
		padding:200px 0 100px;
	}
	.wrapper{
		position:relative;
		z-index: 15;
		h1 {
			font-weight: normal;
			font-family: $primary-font-bold;
			font-size: 65px;
			padding-bottom:30px;
			line-height: 70px;
			color:$accent2;		
		}
		p {
			color:#ffffff;
			font-family: $primary-font-light;
			line-height: 32px;
			font-size: 28px;
		}
	}
	.anchor {
		bottom:50px;
		position:absolute;
		z-index: 20;
		cursor: pointer;
	}
	&:after{
		content: '';
		width: 100%;
		height: 100%;
		background-color:#1D1D1D;
		position:absolute;
		z-index: 5;
		top:0;
		left:0;
		opacity: .87;
	}
}