header {
	background-color:$primary-color;
	padding:26px 0;
	position:fixed;
	z-index:100;
	width:100%;
	h1,input{
		display: inline-block;
	}
	img{
		width: 95px;
	}
	input{
		padding:10px 25px;
		vertical-align: top;
		border:none;
		margin-left: 20px;
		width: calc(100% - 250px);
		outline:none;
		color:$primary-color;
		font-size: 14px;
	}
	.menu-toggle {
		width: 30px;
		height: 30px;
		position: absolute;
	    top: 0px;
	    right: 0px;
		cursor: pointer;
		z-index: 100000;
		display: block;
		@media screen and (max-width: $break + 30) {
			right: 30px;
		}
		&.on {
			.one {
				@include transform(rotate(45deg) translate(7px, 7px));
			}
			.two {
				opacity: 0;
			}
			.three {
				@include transform(rotate(-45deg) translate(8px, -9px));
			}
		}
		.one,
		.two,
		.three {
			width: 100%;
			height: 5px;
			background: #ffffff;
			margin: 6px auto;
			backface-visibility: hidden;
			-moz-transition-duration: 0.3s;
			-o-transition-duration: 0.3s;
			-webkit-transition-duration: 0.3s;
			transition-duration: 0.3s;
		}
	}
}

.mobile-menu {
	top:92px;
	position:fixed;
	background-color:$primary-color;
    z-index: 90;
    display: none;
    ul {
    	padding:10px 0;
	    li {
	    	list-style: none;
	    	line-height: 30px;
	    	text-align: right;
	    	a {
	    		color:#ffffff;
	    		font-family: $primary-font-light;
	    		text-decoration: none;
	    	}
	    }
    }
}