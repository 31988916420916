.job-buckets{
	padding:40px 60px 85px;
	overflow:hidden;
	position:relative;
	@include box-sizing(border-box);
	h2{
		font-size: 42px;
		padding-bottom:30px;
	}
	.content{
		z-index: 10;
		position:relative;
		max-width: 1300px;
		margin:0 auto;
		.col-33-gutter {
			height:307px;
			padding:28px 30px;
			position:relative;
			background-color:#ffffff;
			border:1px solid #CCCCCC;
			@include box-sizing(border-box);
			@media screen and (max-width:$break) {
				height: auto;
				padding-bottom:70px;
			}
			.category {
				text-transform: uppercase;
				letter-spacing: 3px;
			}
			h2{
				color:#333333;
				font-size: 30px;
				padding:13px 0;
			}
			p{
				font-family: $primary-font-light;
				color:#8200FF;
				line-height: 24px;
			}
			.learn-more{
				position:absolute;
				bottom: 10px;
				padding: 20px 40px 20px 0px;
				text-decoration: none;
				div {
					color:#333333;
					font-size: 14px;
					position:relative;
					letter-spacing: 3px;
					text-transform: uppercase;
					font-family: $primary-font-light;
					img {
						top: -6px;
						right: -35px;
						position:absolute;
						@include transition(all, $duration);
					}
				}
				&:hover img {
					right: -30px;
					@include transition(all, $duration);
				}
			}
		}
		.button-container{
			display: inline-block;
			width: 100%;
			margin-top: 30px;
			@media screen and (max-width: $tablet) {
				margin-top: 0;
			}
			.button{
				clear: both;
				border:2px solid $primary-color;
				background-color:$primary-color;
				text-decoration: none;
				font-family: $primary-font-regular;
				padding:10px 26px 8px;
				color:#ffffff;
				display: inline-block;
				@include box-sizing(border-box);
				@include border-radius(30px);
				&:hover{
					background-color:transparent;
					color:$primary-color;
				}
			}
		}
	}
	.gradient {
		position:absolute;
		top:0;
		left:0;
		height: 100%;
		width: 100%;
		z-index: 0;
		background: rgba(255,255,255,1);
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(241,241,241,1)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0 );		
	}
}