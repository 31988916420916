.map-app{
	padding:60px 0;
	text-align:center;
	position:relative;
	&.action .map-container{
		.right-panel {
			opacity: 1;
		}
		.map {
			margin-left: 20px;
			@include transition(all, $duration);
			@media screen and (max-width:$soda){
				margin-left: 0;	
				float: none;
			}
			svg{
				margin-top: -70px;
				width: 450px;
				@include transition(all, $duration);
				@media screen and (max-width:$soda){
					margin:0 auto;
					display: block;
				}
				@media screen and (max-width:450px){
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	#anchor {
		top: -140px;
	    position: absolute;
	    display: block;
	    z-index: 1212121;
	    width: 20px;
	    height: 1px;
	    @media screen and (max-width:$soda){
	    	bottom:90px;
	    	top:auto;
	    }
	}
	.map,
	.right-panel {
		display: inline-block;
		float: left;
	}
	.map-container {
		width: 1000px;
		margin:0 auto;
		position:relative;
		@media screen and (max-width:$soda){
			width: 100%;
		}
		#bubble {
			width: 210px;
			height: 210px;
			background-color:$accent3;
			@include border-radius(50%);
			position:absolute;
			padding-top:50px;
			left:0px;
			top:40px;
			@include box-sizing(border-box);
			@include transform(scale(.15));
			@include transition(all, $duration-long);
			@media screen and (max-width:450px){
				width: 140px;
				height: 140px;
				padding-top:20px;
			}
			&.active{
				opacity: 1;
				@include transform(scale(1));
				@include transition(all 500ms cubic-bezier(0.5, 0.000, 0.000, 1.5));
				span,p{
					opacity: 1;
					@include transition(all, $duration);
				}
			}
			span,p{
				opacity: 0;
			}
			span {
				color: #ffffff;
				font-family:$primary-font-bold;
				font-size: 60px;
				@media screen and (max-width:450px){
					font-size: 45px;
				}
			}
			p {
				color: #ffffff;
				font-size: 18px;
				padding-top:10px;
				font-family:$primary-font-light;
				@media screen and (max-width:450px){
					font-size: 14px;
				}
			}
		}
		.map {
			display: block;
			margin-left: calc(50% - 310px);
			@include transition(all, $duration);
			font-family: $primary-font-lightItalic;
			padding-left:40px;
			position:relative;
			svg {
				@include transition(all, $duration);
				&> g > g {
					cursor:pointer;
					&:hover > path {
						fill:#0FDEBD
					}&.active > path {
						fill:$accent3;
					}
				}
			}
		}
		.right-panel {
			width: 400px;
			text-align: left;
			padding:40px 0 0 80px;
			opacity: 0;
			@media screen and (max-width:$soda){
				width:100%;
				float: none;
				padding:40px;
				@include box-sizing(border-box);
			}
			h4{
				color:$accent;
				font-size: 36px;
				padding-bottom:20px;
				font-family: $primary-font-lightItalic;
			}
		}
	}
	#directions li{
		list-style: none;
		color:$accent;
		padding:20px 30px;
		margin-bottom:10px;
		position:relative;
		@include box-shadow(1px,2px,15px,rgba(0,0,0,.2));
		span {
			padding:4px 10px;
			font-family: $primary-font-regular;
			color:#ffffff;
		}
		.line-icon{
			margin:-6px 2px;
			width: 25px;
			padding:0 6px;
		}
		.fixed-icon {
			position:absolute;
			bottom:5px;
			right:5px;
			opacity: .25;
			height: 40px;
		}
	}
}