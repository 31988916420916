@font-face {
    font-family: "Graphik LightItalics";
    src: url("../fonts/Graphik-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: "Graphik Light";
    src: url("../fonts/Graphik-Light.otf") format("opentype");
}

@font-face {
    font-family: "Graphik Super";
    src: url("../fonts/Graphik-Super.otf") format("opentype");
}

@font-face {
    font-family: "Graphik Regular";
    src: url("../fonts/Graphik-Regular.otf") format("opentype");
}

h1,h2,p,a{
	font-family:$primary-font-bold;
}

h3,h4, li{
	font-family:$primary-font-regular;
}

h1,h2{
	font-size: 64px;
	color: #360060;
}

li {
	font-size:16px;
	line-height:24px;
}