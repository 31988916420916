///Break Points
$grid: 1400px;
$break: 1130px;
$soda:1000px;
$tablet: 768px;
$handheld: 600px;
$mobile: 400px;
$iphone5: 320px;

///Fonts
$primary-font-light:"Graphik Light",sans-serif;
$primary-font-lightItalic:"Graphik LightItalics",sans-serif;
$primary-font-bold: "Graphik Super",sans-serif; 
$primary-font-regular: "Graphik Regular",sans-serif; 

///Colors
$primary-color:#8200FF; //purple
$secondary-color:#F7F7F7; //gray
$accent:#360060; //purple
$accent2: #0FDEBD; //green
$accent3: #ff3366; //cherry 

///Animation 
$duration:200ms;
$duration-long:800ms;